import { Injectable } from '@angular/core';
import { FlamingoHttpService } from '@flamingo/service/flamingo-http.service';
import { Observable } from 'rxjs';
import { LoginDto } from './dto/login.dto';

@Injectable()
export class AuthService {

  constructor(private http: FlamingoHttpService) {
  }

  login(dto: LoginDto): Observable<any> {
    return this.http.post('/auth/login', dto);
  }

  resetEmail(username: string): Observable<any> {
    return this.http.post('/auth/reset-code', { username });
  }

  checkCode(codeDto: { username: string, code: string }): Observable<any> {
    return this.http.post('/auth/check-code', codeDto);
  }

  activate(activateDto: { username: string, code: string, password: string }): Observable<any> {
    return this.http.post('/auth/activate', activateDto);
  }
}
