import { Company } from '@x/model/company.model';

export interface BaseUser {
  readonly id: number;
  readonly username: string;
  readonly email: string;
  readonly title: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly phone: string;
  readonly enabled: boolean;
  readonly verified: boolean;
  readonly created: string;
  readonly updated: string;
  readonly acceptAgreementUpdated: string;
  readonly acceptPrivacyUpdated: string;
  readonly acceptTermsUpdated: string;
  readonly metadata?: any;
  readonly companyAtecoCode: string;
  readonly companyAtecoDescription: string;
  readonly companyId: number;
  readonly companyName: string;
  readonly companyTaxCode: string;
  readonly companyVatCode: string;
  readonly image: string;
}

export interface User extends BaseUser {
  readonly role: Role;
  readonly company: Company;
  readonly taxCode?: string;
  readonly vatCode?: string;
}

export enum Role {
  ADMIN = 'ADMIN',
  TUTOR = 'TUTOR',
  USER = 'USER',
  ACCOUNTANT = 'ACCOUNTANT',
}

export const RolesLabel = new Map<string, string>([
  [Role.ADMIN, 'AMMINISTRATORE'],
  [Role.TUTOR, 'SPECIALIST'],
  [Role.USER, 'UTENTE'],
  [Role.ACCOUNTANT, 'COMMERCIALISTA'],
]);

export interface UserRole {
  readonly key: Role;
  readonly value: string;
}

export interface CompanyUser extends User {
  pillarE: boolean;
  pillarS: boolean;
  pillarG: boolean;
}
